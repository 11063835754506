<template>
  <div class="card">
    <div class="card-body">
      <div class="row success">
        <div class="col-xl-8">
          <h5 style="color: blue; margin-bottom: 30px">
            <span style="margin-right: 30px"> {{ $t("bulletin.notes") }}</span>
            <a-tooltip
              placement="right"
              v-if="selectedClasse && selectedTrimester"
            >
              <template slot="title">
                {{
                  isValidatedBulletin()
                    ? $t("bulletin.hideBulletin")
                    : $t("bulletin.validateBulletin")
                }}
              </template>
              <span>
                <a-button
                  :type="isValidatedBulletin() ? 'primary' : 'danger'"
                  @click="confirmeBulletinValidation()"
                >
                  {{
                    isValidatedBulletin()
                      ? $t("bulletin.cancelValidation")
                      : $t("bulletin.validatedClassroom")
                  }}
                </a-button>
              </span>
            </a-tooltip>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <label>{{ $t("bulletin.listeClasses") }}</label>
          <a-select
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 150px; margin-left: 15px"
            @change="
              (val) => {
                handleClassChange('classe', val);
              }
            "
          >
            <a-select-option value="all"> --- </a-select-option>
            <a-select-option
              v-for="classe in classes"
              :key="classe._id"
              :value="classe._id"
            >
              {{ classe.name }}
            </a-select-option>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("menu.eleves")
          }}</label>

          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :placeholder="$t('menu.eleves')"
            style="width: 180px; margin-left: 15px"
            :loading="studentLoading"
            v-model="selectedStudent"
            @change="
              (val) => {
                this.selectedStudent = val;

                this.selectedStudentName = this.activeData.find(
                  (s) => s._id == val
                ).fullName;
                handleStudentChange(val);
              }
            "
          >
            <a-select-option
              v-for="student in activeData"
              :key="student._id"
              :value="student._id"
            >
              {{ student.fullName }}
            </a-select-option>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("emploi.trimestre")
          }}</label>
          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 50px; margin-left: 15px"
            @change="
              (val) => {
                this.selectedTrimester = val;
                handleClassChange('classe', this.selectedClasse);
                if (selectedStudent) updateMarks();
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>

          <a-dropdown style="margin-right: 5px" v-show="selectedClasse">
            <template #overlay>
              <a-menu
                @click="
                  (val) => {
                    if (val.key == '1') printBulletinSpec(false);
                    else if (val.key == '2') printAllBulletinSpec(false);
                    else if (val.key == '3') openFinalBulletin();
                    else if (val.key == '4') openAllFinalBulletin();
                    else if (val.key == '5') printPVAnnuel();
                    else if (val.key == '6') printPVAnnuelByLevel();
                    else if (val.key == '7')
                      printBulletinSpec(schoolAccessTemplateID);
                    else if (val.key == '8')
                      printAllBulletinSpec(schoolAccessTemplateID);
                  }
                "
              >
                <a-menu-item-group key="g1" title="Bulletins">
                  <a-menu-item key="1" v-if="selectedStudent">
                    {{ $t("bulletin.imprimerBulletin") }}
                  </a-menu-item>
                  <a-menu-item key="2">
                    {{ $t("bulletin.imprimerTout") }}
                  </a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group key="g4" v-if="schoolAccessTemplateID">
                  <template slot="title">
                    <span class="pr-1">{{
                      "Format " + schoolDetails.name
                    }}</span>
                    <a-icon
                      type="star"
                      theme="filled"
                      :style="{ color: 'yellow' }"
                    />
                  </template>
                  <a-menu-item key="7" v-if="selectedStudent">
                    {{ $t("bulletin.imprimerBulletin") }}
                  </a-menu-item>
                  <a-menu-item key="8">
                    {{ $t("bulletin.imprimerTout") }}
                  </a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group key="g2" title="Bulletin annuel">
                  <a-menu-item key="3" v-if="selectedStudent">{{
                    $t("Imprimer bulletin")
                  }}</a-menu-item>
                  <a-menu-item key="4">{{ $t("Imprimer tout") }}</a-menu-item>
                </a-menu-item-group>

                <a-menu-item-group key="g3" title="PV Annuel">
                  <a-menu-item key="5">Imprimer PV</a-menu-item>
                  <a-menu-item key="6">Imprimer PV par niveau</a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
            <a-button
              class="ml-2"
              style="float: right"
              type="primary"
              :loading="printLoading"
              :disabled="printLoading"
              icon="file-pdf"
            >
              {{ $t("action.imprimer") }}
            </a-button>
          </a-dropdown>

          <br />
          <br />
          <div class="card" v-if="selectedStudent && selectedClasse">
            <a-table
              :columns="columns"
              :data-source="subjectList"
              :loading="tableLoading"
              :scroll="{ x: 'max-content' }"
            >
              <template slot="note" slot-scope="text, record">
                <div
                  v-for="item in record.subsubjectData"
                  :key="item._id"
                  class="d-flex"
                  :class="
                    getMarkValue(item._id, selectedStudent, true) < 0 ||
                    getMarkValue(item._id, selectedStudent, true) > item.maxMark
                      ? 'danger'
                      : ''
                  "
                >
                  <span class="ant-input-wrapper">
                    <span
                      class="ant-input-group-addon"
                      style="min-width: 175px !important"
                      >{{ item.name }}</span
                    >
                    <a-input-number
                      :value="getMarkValue(item._id, selectedStudent, true)"
                      style="height: 35px; width: 65px; text-align: center"
                      @change="
                        (val) => {
                          handleMarkChange(
                            val,
                            record._id,
                            item._id,
                            selectedStudent,
                            true
                          );
                        }
                      "
                    ></a-input-number>
                    <span class="ant-input-group-addon"
                      >/{{ item.maxMark }}</span
                    >
                  </span>
                  <a-popconfirm
                    :title="$t('all.sureToDelete')"
                    @confirm="() => deleteMark(item._id, selectedStudent, true)"
                  >
                    <a-button
                      tabindex="-1"
                      type="dashed"
                      shape="circle"
                      class="ml-2"
                      icon="delete"
                    ></a-button>
                  </a-popconfirm>
                </div>

                <span v-if="record.divided" class="ant-input-wrapper">
                  <a-input-number
                    :value="getTotalSubsubjects(record)"
                    :disabled="true"
                    style="
                      height: 35px;
                      width: 65px;
                      margin-left: 175px;
                      text-align: center;
                      background-color: #f0f2f4;
                    "
                  ></a-input-number>
                  <span class="ant-input-group-addon">/20</span>
                </span>
                <div
                  v-else
                  :class="
                    getMarkValue(record._id, selectedStudent, false) < 0 ||
                    getMarkValue(record._id, selectedStudent, false) > 20
                      ? 'danger'
                      : ''
                  "
                >
                  <span class="ant-input-wrapper">
                    <a-input-number
                      :value="getMarkValue(record._id, selectedStudent, false)"
                      style="height: 35px; width: 65px; text-align: center"
                      @change="
                        (val) => {
                          handleMarkChange(
                            val,
                            record._id,
                            null,
                            selectedStudent,
                            false
                          );
                        }
                      "
                    ></a-input-number>
                    <span class="ant-input-group-addon">/20</span>
                  </span>

                  <a-popconfirm
                    :title="$t('all.sureToDelete')"
                    @confirm="
                      () => deleteMark(record._id, selectedStudent, false)
                    "
                  >
                    <a-button
                      tabindex="-1"
                      type="dashed"
                      shape="circle"
                      class="ml-2"
                      icon="delete"
                    ></a-button>
                  </a-popconfirm>
                </div>
              </template>
            </a-table>
          </div>
          <div
            class="row"
            v-if="selectedClasse && selectedStudent && activeData.length !== 0"
          >
            <div class="col-xl-7">{{ $t("bulletin.toutMatieres") }}</div>
            <div class="col-xl-3">
              <a-button
                :loading="saveLoading"
                style="
                  margin-right: 5px;
                  margin-left: 20px;
                  margin-bottom: 10px;
                  background: #8bc34a !important;
                  border-color: #8bc34a !important;
                "
                type="primary"
                @click="saveAll()"
              >
                {{ $t("bulletin.enregistrerTout") }}<a-icon type="check" />
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      @ok="
        () => {
          visibleBulletinDate = false;
        }
      "
      @cancel="
        () => {
          visibleBulletinDate = false;
        }
      "
      v-model="visibleBulletinDate"
      :title="$t('bulletin.date')"
      :closable="false"
      :width="500"
      :height="700"
      :key="'bulletinDate'"
    >
      <div>
        <label style="margin-right: 5px">{{ $t("bulletin.date") }}</label>
        <a-date-picker
          :allowClear="false"
          @change="
            (val) => {
              bulletinDate = val;
            }
          "
          :default-value="moment(new Date())"
        />
      </div>
    </a-modal>
    <FinalBulletin
      :visibleFinalBulletin="visibleFinalBulletin"
      :studentID="selectedStudent"
      @closeFinalBulletin="closeFinalBulletin"
      @printLoading="setPrintLoading"
    />
    <AllFinalBulletin
      :visibleFinalBulletin="visibleAllFinalBulletin"
      :classroomID="selectedClasse"
      @closeFinalBulletin="closeAllFinalBulletin"
      @printLoading="setPrintLoading"
    />
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";
import FinalBulletin from "./components/FinalBulletin";
import AllFinalBulletin from "./components/AllFinalBulletin";

export default {
  computed: {
    ...mapState(["settings", "user"]),
    schoolAccessTemplateID() {
      const accessTemplates = [
        { id: "MS", dbName: "MalekEssaghir" },
        { id: "MP", dbName: "mondialPrimaire" },
        { id: "MP", dbName: "COPYmondialPrimaire" },
      ];
      let templateId = accessTemplates.find(
        (e) => e.dbName == this.schoolDetails.dbName
      );
      templateId = templateId ? templateId.id : "";
      console.log(templateId);
      return templateId;
    },
  },
  components: { FinalBulletin, AllFinalBulletin },
  destroyed() {
    window.removeEventListener("beforeunload", this.confirmLeaveReload);
  },
  async created() {
    window.addEventListener("beforeunload", this.confirmLeaveReload);
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletinVerySpec: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));
    apiClient
      .get("/certification/spec")
      .then((res) => {
        this.certifParams = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
    this.tableLoading = false;
    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    // apiClient
    //   .get("/mark/classroomMarksStats/" + this.selectedTrimester + "/normal")
    //   .then(({ data }) => {
    //     this.classroomMarksStats = data;
    //   });
  },

  data() {
    return {
      studentLoading: false,
      visibleBulletinDate: false,
      bulletinDate: new Date(),
      updatedChanges: true,
      printLoading: false,
      syncLoading: false,
      syncSubjectLoading: false,
      visibleClassnameList: false,
      visibleSubjectList: false,
      visibleFinalBulletin: false,
      visibleAllFinalBulletin: false,
      schoolType: null,
      filtredTable: [],
      classroomMarksStats: {},
      selectedStudent: this.$t("menu.eleves"),
      selectedStudentName: "",
      bulletins: { students: [] },
      selectedClassName: "",
      markData: [],
      originalMarkData: [],
      activeModule: null,
      bulletinData: [],
      certifParams: [],
      moduleLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      saveLoading: false,
      activeData: [],
      markRefs: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [],
      subsubjectData: [],
      subjectList: [],
      selectedLevel: null,
      bulletinColors: {
        color1: [70, 187, 239],
        color2: [64, 187, 239],
        color3: [16, 169, 229],
        color4: [67, 62, 63],
        color5: [52, 172, 215],
        color6: [224, 237, 243],
        color7: [64, 187, 239],
        color8: [225, 225, 226],
        color9: [224, 237, 243],
      },
      schoolDetails: {},
      schoolarYearName: "",
    };
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;

    if (this.updatedChanges) next();
    else
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk() {
          _this.updatedChanges = true;
          next();
        },
        onCancel() {
          next(false);
        },
      });
  },
  methods: {
    getSumMaxMArks(subsubjectData) {
      let sumMax = 0;
      subsubjectData.forEach((sub) => {
        sumMax += sub.maxMark;
      });
      return sumMax;
    },
    confirmLeaveReload(event) {
      if (!this.updatedChanges) {
        event.returnValue = this.$t("all.unsavedchangesContent");
      }
    },
    async openFinalBulletin() {
      this.$gtag.event("Imp allBulletinAnnuel", {
        event_category: "Impression PDF",
        event_label: "Bulletin specific-NotesEleves section",
        value: 1,
      });

      this.visibleFinalBulletin = true;
    },
    async openAllFinalBulletin() {
      this.$gtag.event("Imp BulletinAnnuel", {
        event_category: "Impression PDF",
        event_label: "Bulletin specific-NotesEleves section",
        value: 1,
      });

      this.visibleAllFinalBulletin = true;
    },
    closeFinalBulletin() {
      this.visibleFinalBulletin = false;
    },
    closeAllFinalBulletin() {
      this.visibleAllFinalBulletin = false;
    },
    setPrintLoading(val) {
      this.printLoading = val;
    },
    moment,
    confirmeBulletinValidation() {
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    isValidatedBulletin() {
      if (this.selectedClasse) {
        const bulletin = this.classes.find(
          (item) => item._id === this.selectedClasse
        ).bulletinVerySpec || { sem1: false, sem2: false, sem3: false };
        if (this.selectedTrimester == 1 && bulletin.sem1 == true) return true;
        else if (this.selectedTrimester == 2 && bulletin.sem2 == true)
          return true;
        else if (this.selectedTrimester == 3 && bulletin.sem3 == true)
          return true;
      }
      return false;
    },
    validateBulletin() {
      const isValidated = this.isValidatedBulletin();
      const bulletin = this.classes.find(
        (item) => item._id === this.selectedClasse
      ).bulletinVerySpec || { sem1: false, sem2: false, sem3: false };
      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: {
            bulletinVerySpec: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            avaliable: !isValidated,
            type: "verySpecific",
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse)
              item.bulletinVerySpec = bulletin;
            return item;
          });

          if (!isValidated)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
        });
    },
    async sleep(timeout) {
      await new Promise((r) => setTimeout(r, timeout));
    },
    async chooseBulletinDate() {
      this.visibleBulletinDate = true;
      return new Promise(async (res) => {
        while (this.visibleBulletinDate) {
          await this.sleep(500);
        }
        res();
      });
    },
    getCertificationNameByMoyenne(moyenne) {
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        ) {
          return cert.name;
        }

      return "";
    },
    async printAllBulletinSpec(useTemplateSchool) {
      this.$gtag.event("Imp all specific bulletins", {
        event_category: "Impression PDF",
        event_label: "Bulletin specific-NotesMatiere section",
        value: 1,
      });
      this.printLoading = true;
      const doc = new JsPDF();
      const ctx = doc.context2d;
      let bulletinData;
      let firstPage = true;
      let affichageBulletin;

      await apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: "verySpecific",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            affichageBulletin = res.data[0];
          } else {
            affichageBulletin = {
              verySpecific: true,
              pedagogic: false,
              personalized: false,
              logo: true,
              verySpecificName: "معدل",
            };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        });

      await apiClient
        .get(
          "/markvspecific/all/" +
            this.selectedClasse +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          bulletinData = data;
        });

      //stamp
      let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
      const stampImage = new Image(30, 30);
      if (stamp) stampImage.src = stamp;
      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
        } catch (e) {}
      }
      //end stamp

      await this.chooseBulletinDate();
      if (
        (!useTemplateSchool && affichageBulletin.logo) ||
        useTemplateSchool == "MP"
      ) {
        let arrayOfImages = this.settings.image;
        for (var i = 0; i < arrayOfImages.length; i++) {
          if (this.settings.activeBuilding === arrayOfImages[i].db) {
            var imgData = new Image();
            imgData.src = arrayOfImages[i].logo;
          }
        }
      }
      const pdf_width = doc.internal.pageSize.getWidth();
      bulletinData.students.forEach((student) => {
        if (!firstPage) {
          doc.addPage();
        }
        if (!useTemplateSchool && affichageBulletin.logo) {
          doc.addImage(imgData, "JPEG", pdf_width / 2 - 10, 6, 14, 14);
        }
        if (useTemplateSchool == "MP") {
          doc.addImage(imgData, "JPEG", pdf_width / 2 - 10, 0, 20, 20);
          this.drawBulletinMondial(
            doc,
            ctx,
            student,
            bulletinData,
            affichageBulletin,
            stampImage
          );
        } else if (useTemplateSchool == "MS")
          this.drawBulletinMalekEsaghir(
            doc,
            ctx,
            student,
            bulletinData,
            affichageBulletin,
            stampImage
          );
        else
          this.drawBulletin(
            doc,
            ctx,
            student,
            bulletinData,
            affichageBulletin,
            stampImage
          );
        firstPage = false;
      });
      this.printLoading = false;

      const className = this.selectedClassName;
      doc.save(`Bulletin ${className}.pdf`);
    },

    async printBulletinSpec(useTemplateSchool) {
      this.$gtag.event("Imp specific bulletin", {
        event_category: "Impression PDF",
        event_label: "Bulletin specific-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;
      const doc = new JsPDF();
      const ctx = doc.context2d;
      let bulletinData;
      let affichageBulletin;

      await apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: "verySpecific",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            affichageBulletin = res.data[0];
          } else {
            affichageBulletin = {
              verySpecific: true,
              pedagogic: false,
              personalized: false,
              logo: true,
              verySpecificName: "معدل",
            };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        });

      await apiClient
        .get(
          "/markvspecific/" +
            this.selectedStudent +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          bulletinData = data;
        });
      /*add logo*/
      //MS id template for Malek Essaghir school
      if (useTemplateSchool != "MS" && affichageBulletin.logo) {
        let arrayOfImages = this.settings.image;
        for (var i = 0; i < arrayOfImages.length; i++) {
          if (this.settings.activeBuilding === arrayOfImages[i].db) {
            var imgData = new Image();
            imgData.src = arrayOfImages[i].logo;
          }
        }
        const pdf_width = doc.internal.pageSize.getWidth();
        if (useTemplateSchool == "MP")
          doc.addImage(imgData, "JPEG", pdf_width / 2 - 10, 0, 20, 20);
        else doc.addImage(imgData, "JPEG", pdf_width / 2 - 7, 6, 14, 14);
      }
      //stamp
      let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
      const stampImage = new Image(30, 30);
      if (stamp) stampImage.src = stamp;
      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
        } catch (e) {}
      }
      //end stamp

      await this.chooseBulletinDate();
      if (useTemplateSchool == "MP") {
        console.log("template mondial");
        this.drawBulletinMondial(
          doc,
          ctx,
          bulletinData,
          bulletinData,
          affichageBulletin,
          stampImage
        );
      } else if (useTemplateSchool == "MS") {
        this.drawBulletinMalekEsaghir(
          doc,
          ctx,
          bulletinData,
          bulletinData,
          affichageBulletin,
          stampImage
        );
      } else
        this.drawBulletin(
          doc,
          ctx,
          bulletinData,
          bulletinData,
          affichageBulletin,
          stampImage
        );

      this.printLoading = false;

      doc.save(`Bulletin specifique ${this.selectedStudentName}.pdf`);
    },

    async printPVAnnuel() {
      this.$gtag.event("Imp PV BulletinSpecific Annuel", {
        event_category: "Impression PDF",
        event_label: "Bulletin specific-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;
      const cellToDelete = {};

      const doc = new JsPDF({
        orientation: "m",
        unit: "mm",
        format: "a3",
      });

      doc.setFont("Amiri");

      const pdf_width = doc.internal.pageSize.getWidth();

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

      const className = this.selectedClassName;

      doc.text("PV Annuel Spécifique " + className, pdf_width / 2, 20, {
        align: "center",
      });

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.setFontSize(9);

      doc.text(
        new Date().toLocaleDateString("fr-FR", options),
        pdf_width - 17,
        8,
        {
          align: "right",
        }
      );

      let bulletinData;

      await apiClient
        .get("/verySpecificBulletin/final/all/" + this.selectedClasse)
        .then((res) => (bulletinData = res.data));

      let array = [
        [
          { content: "الرتبة", styles: { fontStyle: "Bold" } },
          { content: "معدل العام", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
          { content: "الأسم", styles: { fontStyle: "Bold" } },
          { content: "#", styles: { fontStyle: "Bold" } },
        ],
      ];

      let id = 1;
      bulletinData.students.forEach((student) => {
        const studentData = [];
        studentData.push(student.rank);
        studentData.push(student.finalMoyenne);
        studentData.push(student.moyenneThree);
        studentData.push(student.moyenneTwo);
        studentData.push(student.moyenneOne);
        studentData.push(student.fullName);
        studentData.push(id++);
        array.push(studentData);
      });

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.autoTable({
        startY: 35,
        body: array,
        theme: "striped",
        styles: {},
        bodyStyles: {
          lineWidth: 0.2,
          lineColor: [73, 138, 159],
          halign: "center",
          fontSize: 10,
          font: "Amiri",
          // cellPadding: 1.2,
        },
        rowPageBreak: "avoid",
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(9); //Optional

      doc.setTextColor(0, 0, 0);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      //end page numbering

      this.printLoading = false;

      doc.save(`PV Annuel ${className}.pdf`);
    },

    async printPVAnnuelByLevel() {
      this.$gtag.event("Imp PV Annuel by level", {
        event_category: "Impression PDF",
        event_label: "Bulletin specifique-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;
      const cellToDelete = {};

      const doc = new JsPDF({
        orientation: "m",
        unit: "mm",
        format: "a3",
      });

      doc.setFont("Amiri");

      const pdf_width = doc.internal.pageSize.getWidth();

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

      const levels = {
        "-2": "Préscolaire",
        "-1": "تحضيري",
        1: "سنة أولى",
        2: "سنة ثانية",
        3: "سنة ثالثة",
        4: "سنة رابعة",
        5: "سنة خامسة",
        6: "سنة سادسة",
        7: "سنة سابعة",
        8: "سنة ثامنة",
        9: "سنة تاسعة",
        10: "أولى ثانوي",
        11: "ثانية ثانوي",
        12: "ثالثة ثانوي",
        13: "رابعة ثاتوي",
      };

      doc.text(
        "PV Annuel Spécifique - " + levels[this.selectedLevel],
        pdf_width / 2,
        20,
        {
          align: "center",
        }
      );

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.setFontSize(9);
      doc.text(
        new Date().toLocaleDateString("fr-FR", options),
        pdf_width - 17,
        8,
        {
          align: "right",
        }
      );

      let bulletinData;

      await apiClient
        .get("/verySpecificBulletin/pv/final/" + this.selectedLevel)
        .then((res) => (bulletinData = res.data));

      let array = [
        [
          { content: "الرتبة", styles: { fontStyle: "Bold" } },
          { content: "معدل العام", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
          { content: "القسم", styles: { fontStyle: "Bold" } },
          { content: "الأسم", styles: { fontStyle: "Bold" } },
        ],
      ];

      bulletinData.students.forEach((student) => {
        const studentData = [];
        studentData.push(student.rank);
        studentData.push(student.finalMoyenne);
        studentData.push(student.moyenneThree);
        studentData.push(student.moyenneTwo);
        studentData.push(student.moyenneOne);
        studentData.push(student.classroomName);
        studentData.push(student.fullName);
        array.push(studentData);
      });

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.autoTable({
        startY: 35,
        body: array,
        theme: "striped",
        styles: {},
        bodyStyles: {
          lineWidth: 0.2,
          lineColor: [73, 138, 159],
          halign: "center",
          fontSize: 9,
          font: "Amiri",
          // cellPadding: 1.2,
        },
        columnStyles: {
          0: { cellWidth: 20 },
        },
        rowPageBreak: "avoid",
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(9); //Optional

      doc.setTextColor(0, 0, 0);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      //end page numbering

      this.printLoading = false;

      doc.save(`PV Annuel Spécifique ${levels[this.selectedLevel]}.pdf`);
    },

    async drawBulletin(
      doc,
      ctx,
      student,
      bulletinData,
      affichageBulletin,
      stampImage
    ) {
      this.bulletinColors = {
        color1: [70, 187, 239],
        color2: [64, 187, 239],
        color3: [16, 169, 229],
        color4: [67, 62, 63],
        color5: [52, 172, 215],
        color6: [224, 237, 243],
        color7: [64, 187, 239],
        color8: [225, 225, 226],
        color9: [224, 237, 243],
      };
      const record = student;

      const pdf_width = doc.internal.pageSize.width;
      let width = 0;
      let width2 = 0;

      // ending header line
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.8);
      doc.line(0, 40 - 12, pdf_width, 40 - 12);

      // trimester rounded rect title
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(pdf_width / 2 - 25, 34 - 12, 50, 12, 4, 4, "FD");

      // trimester text
      doc.setFontSize(16);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(...this.bulletinColors.color5);
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "الثلاثي الأول";
          break;
        case "2":
          title = "الثلاثي الثاني";
          break;
        case "3":
          title = "الثلاثي الثالث";
          break;
      }
      doc.setFont("Noto Sans Arabic", "Bold");
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 41.2 - 12);

      const className = this.selectedClassName;

      let studentLabel =
        record.gender === "male" ? " : التلميذ" : " : التلميذة";

      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);

      doc.setTextColor(0, 0, 0);

      let schoolTypeText =
        this.selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
      text = this.schoolDetails.ArabicName
        ? schoolTypeText + this.schoolDetails.ArabicName
        : "................................................... : المدرسة الابتدائيّة";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 12);
      text = ` ${this.schoolarYearName}  : السنة الدراسيّة`;
      width = doc.getTextWidth(text);
      doc.text(text, 10, 12);

      let text = studentLabel;
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 17.5);

      text = student.fullName;
      doc.setFont("Amiri", "normal");
      doc.setFontSize(13);
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - width2 - 12, 17.5);
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      // student classroom
      text = " : القسم";
      doc.setTextColor(0, 0, 0);
      width = doc.getTextWidth(text);
      width2 = doc.getTextWidth(className);
      doc.text(text, 13 + width2, 17.5);

      doc.text(className, 12, 17.5);
      // number of students in classroom
      if (affichageBulletin.numberStudentInsc) {
        text = String(
          Array.isArray(bulletinData.students)
            ? bulletinData.students.length
            : bulletinData.students
        );
        width2 = doc.getTextWidth(text);
        doc.text(text, 12, 23);
        text = " : عدد التلاميذ المرسّمين";
        width = doc.getTextWidth(text);
        doc.text(text, 12 + width2, 23);
      }

      // identUnique
      if (affichageBulletin.identUnique && student.uniqueId) {
        text = " : المعرف الوحيد";
        width = doc.getTextWidth(text);
        doc.text(text, pdf_width - width - 12, 23);
        text = String(student.uniqueId);
        width2 = doc.getTextWidth(text);
        doc.text(text, pdf_width - width2 - width - 12, 23);
      }
      const cellToDelete = {};
      let topMargin = -21;
      let tableSize = 9;
      switch (this.selectedLevel) {
        case 1:
          tableSize = 11.5;
          break;
        case 2:
          tableSize = 11.5;
          break;
        case 3:
          tableSize = 10.5;
          break;
        case 4:
          tableSize = 9.5;
          break;
        default:
          break;
      }

      student.modules.forEach((m) => {
        //try {
        let arr = [];
        let array = [];

        this.drawModuleHeader(doc, ctx, topMargin, m.name);
        topMargin += 17.2;

        m.subjects.forEach((c) => {
          let subFormattedName = c.name;
          if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
            let indexOfFirst = c.name.indexOf(" و ");
            subFormattedName =
              c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
          }

          const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
          //ﺍﻟﻤﺎﺩﺓ
          arr[8] = {
            content: subFormattedName,
            rowSpan: spanNumber,
            colSpan: spanNumber == 1 ? 3 : 1,
            styles: {
              halign: "right",
              valign: "middle",

              fillColor: this.rgbToHex(...this.bulletinColors.color7),
              fontSize: tableSize,
              textColor: "#ffffff",
              lineColor: [229, 229, 229],
            },
          };
          // ﺍﻟﻌﺪﺩ / 20
          arr[5] = {
            content: c.mark,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          arr[4] = cellToDelete;
          arr[3] = cellToDelete;
          arr[2] = cellToDelete;

          //best mark
          arr[1] = {
            content: c.max,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 9,
              cellPadding: {
                right: 0,
                left: 0,
                top: 0,
                bottom: 1.25,
              },
              lineColor: [...this.bulletinColors.color5],
            },
          };

          //lowest mark
          arr[0] = {
            content: c.min,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 9,
              cellPadding: {
                right: 0,
                left: 0,
                top: 0,
                bottom: 1.25,
              },
              lineColor: [...this.bulletinColors.color5],
            },
          };
          //sub subjects
          if (c.subSubjectMarks)
            c.subSubjectMarks.forEach((subs) => {
              try {
                arr[6] = {
                  content: subs.mark,
                  styles: {
                    halign: "center",
                    valign: "middle",
                    cellPadding: 0,
                    fontSize: 8,
                    fillColor: this.rgbToHex(...this.bulletinColors.color9),
                    textColor: this.rgbToHex(...this.bulletinColors.color2),
                    lineColor: this.bulletinColors.color2,
                  },
                };

                arr[7] = {
                  content: `${subs.name} (/${subs.maxMark})`,
                  styles: {
                    halign: "right",
                    valign: "middle",
                    fontSize: 7,
                    fillColor: this.rgbToHex(...this.bulletinColors.color9),
                    textColor: this.rgbToHex(...this.bulletinColors.color2),
                    lineColor: this.bulletinColors.color2,
                  },
                };
                array.push(arr);
                arr = [];
              } catch (error) {
                console.error("error sub", error);
              }
            });
          else array.push(arr);
          arr = [];
        });

        //end shifting

        array[0][2] = {
          content: "",
          rowSpan: array.length,
          styles: {
            lineColor: [...this.bulletinColors.color5],
          },
        };
        //appreciation

        if (
          !m.submodules ||
          m.submodules[0].NbSubjects == 0 ||
          m.submodules[1].NbSubjects == 0
        ) {
          array[0][3] = {
            content: m.appreciation.trim(),
            rowSpan: array.length,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: m.appreciation.length > 15 ? 10 : 12,
              lineColor: [...this.bulletinColors.color5],
            },
          };
        } else {
          array[0][3] = {
            content: m.submodules[0].appreciation.trim(),
            rowSpan: m.submodules[0].NbSubjects,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
          array[m.submodules[0].NbSubjects][3] = {
            content: m.submodules[1].appreciation.trim(),
            rowSpan: m.submodules[1].NbSubjects,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
        }
        //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
        array[0][4] = {
          content: m.moyenne,
          rowSpan: array.length,
          styles: {
            halign: "center",
            fontSize: 12,
            valign: "center",
            lineColor: [...this.bulletinColors.color5],
          },
        };

        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }
        doc.autoTable({
          startY: 64 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          body: array,
          margin: { left: 65, bottom: 0 },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },
          columnStyles: {
            0: { cellWidth: 10 },
            1: { cellWidth: 10 },
            2: { cellWidth: 1 },
            3: { cellWidth: 38 },
            4: { cellWidth: 15 },
            5: { cellWidth: 16 },
            6: { cellWidth: 8 },
            7: { cellWidth: 18 },
            8: { cellWidth: 23 },
          },
        });

        topMargin = doc.lastAutoTable.finalY - 61.4 + 2;
        /*} catch (error) {
          console.error("error.stack");

          console.trace(error);
        }*/
      });

      this.drawTrimesterMoyenneAndCards(
        doc,
        ctx,
        -27,
        student.moyenne,
        bulletinData.maxMoyenne,
        bulletinData.minMoyenne,
        student.pedaMoyenne,
        affichageBulletin,
        stampImage
      );
    },
    async drawBulletinMalekEsaghir(
      doc,
      ctx,
      student,
      bulletinData,
      affichageBulletin,
      stampImage
    ) {
      console.log("using drawBulletinMalekEsaghir template ");
      //bulletinTemplateColors
      this.bulletinColors = {
        color1: [235, 171, 94], //color boarder module header
        color2: [242, 154, 48], //color bg ﻣﺠﺎﻝ  ﺍﻟﻤﺎﺩﺓ
        color3: [245, 155, 47],
        color4: [67, 62, 63], //color bg subjucts
        color5: [247, 148, 29], //247, 148, 29
        color6: [240, 227, 204],
        color7: [242, 154, 48], //color bg subjucts
        color8: [247, 247, 250], //color bg cell ﺃﻋﻠﻰ ﻣﻌﺪﻝ ﺑﺎﻟﻘﺴﻢ
        color9: [240, 227, 204], //
      };

      const record = student;
      // Load your image as a data URL or use an existing image URL
      const imageUrl =
        "/resources/images/templatesBulletin/tepmplate_malek_essaghir_modifed.png";

      // Add the image as a background
      const pdf_width = doc.internal.pageSize.width;
      const pdf_height = doc.internal.pageSize.height;

      let width = 0;
      let width2 = 0;
      doc.addImage(imageUrl, "JPEG", 0, 0, pdf_width, pdf_height);
      let yMargin = 8;
      // ending header line
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.5);
      doc.line(0, 40 - yMargin, pdf_width, 40 - yMargin);

      // trimester rounded rect title
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(pdf_width / 2 - 25, 34 - yMargin, 50, 12, 4, 4, "FD");

      // trimester text
      doc.setFontSize(16);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(...this.bulletinColors.color5);
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "الثلاثي الأول";
          break;
        case "2":
          title = "الثلاثي الثاني";
          break;
        case "3":
          title = "الثلاثي الثالث";
          break;
      }
      doc.setFont("Noto Sans Arabic", "Bold");
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 41.2 - yMargin);

      const className = this.selectedClassName;

      let studentLabel =
        record.gender === "male" ? " : التلميذ" : " : التلميذة";

      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);

      doc.setTextColor(0, 0, 0);

      let schoolTypeText =
        this.selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
      text = this.schoolDetails.ArabicName
        ? schoolTypeText + this.schoolDetails.ArabicName
        : "................................................... : المدرسة الابتدائيّة";
      let widthSc = doc.getTextWidth(text);
      doc.text(text, pdf_width / 2 - widthSc / 2, 12);

      text = ` ${this.schoolarYearName}  : السنة الدراسيّة`;
      width = doc.getTextWidth(text);
      doc.text(text, 10, 12);
      doc.setFont("Amiri", "normal");

      let text = studentLabel;
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width / 2 + widthSc / 4 - 1, 17.5);

      text = student.fullName;
      doc.setFont("Amiri", "normal");
      doc.setFontSize(13);
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width / 2 - width2 + widthSc / 4 - 1, 17.5);
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      // student classroom
      text = " : القسم";
      doc.setTextColor(0, 0, 0);
      width = doc.getTextWidth(text);
      width2 = doc.getTextWidth(className);
      doc.text(text, 13 + width2, 17.5);

      doc.text(className, 12, 17.5);
      // number of students in classroom
      if (affichageBulletin.numberStudentInsc) {
        text = String(
          Array.isArray(bulletinData.students)
            ? bulletinData.students.length
            : bulletinData.students
        );
        width2 = doc.getTextWidth(text);
        doc.text(text, 12, 23);
        text = " : عدد التلاميذ المرسّمين";
        width = doc.getTextWidth(text);
        doc.text(text, 12 + width2, 23);
      }

      // identUnique
      if (affichageBulletin.identUnique && student.uniqueId) {
        text = String(student.uniqueId) + " : المعرف الوحيد";
        width = doc.getTextWidth(text);
        doc.text(text, pdf_width / 2 - width / 2, 23);
      }
      const cellToDelete = {};
      let topMargin = -21;
      let tableSize = 9;
      switch (this.selectedLevel) {
        case 1:
          tableSize = 11.5;
          break;
        case 2:
          tableSize = 11.5;
          break;
        case 3:
          tableSize = 10.5;
          break;
        case 4:
          tableSize = 9.5;
          break;
        default:
          break;
      }

      student.modules.forEach((m) => {
        //try {
        let arr = [];
        let array = [];

        this.drawModuleHeader(doc, ctx, topMargin, m.name);
        topMargin += 17.2;

        m.subjects.forEach((c) => {
          let subFormattedName = c.name;
          if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
            let indexOfFirst = c.name.indexOf(" و ");
            subFormattedName =
              c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
          }

          const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
          //ﺍﻟﻤﺎﺩﺓ
          arr[8] = {
            content: subFormattedName,
            rowSpan: spanNumber,
            colSpan: spanNumber == 1 ? 3 : 1,
            styles: {
              halign: "right",
              valign: "middle",

              fillColor: this.rgbToHex(...this.bulletinColors.color7),
              fontSize: tableSize,
              textColor: "#ffffff",
              lineColor: [229, 229, 229],
            },
          };
          // ﺍﻟﻌﺪﺩ / 20
          arr[5] = {
            content: c.mark,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          arr[4] = cellToDelete;
          arr[3] = cellToDelete;
          arr[2] = cellToDelete;

          //best mark
          arr[1] = {
            content: c.max,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 9,
              cellPadding: {
                right: 0,
                left: 0,
                top: 0,
                bottom: 1.25,
              },
              lineColor: [...this.bulletinColors.color5],
            },
          };

          //lowest mark
          arr[0] = {
            content: c.min,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 9,
              cellPadding: {
                right: 0,
                left: 0,
                top: 0,
                bottom: 1.25,
              },
              lineColor: [...this.bulletinColors.color5],
            },
          };
          //sub subjects
          if (c.subSubjectMarks)
            c.subSubjectMarks.forEach((subs) => {
              try {
                arr[6] = {
                  content: subs.mark,
                  styles: {
                    fontStyle: "Bold",
                    cellPadding: 0,
                    halign: "center",
                    valign: "middle",
                    fontSize: 8,
                    fillColor: this.rgbToHex(...this.bulletinColors.color9),
                    textColor: this.rgbToHex(...[0, 0, 0]),
                    lineColor: this.bulletinColors.color2,
                  },
                };

                arr[7] = {
                  content: `${subs.name} (/${subs.maxMark})`,
                  styles: {
                    halign: "right",
                    valign: "middle",
                    fontSize: 7,
                    fillColor: this.rgbToHex(...this.bulletinColors.color9),
                    textColor: this.rgbToHex(...this.bulletinColors.color2),
                    lineColor: this.bulletinColors.color2,
                  },
                };
                array.push(arr);
                arr = [];
              } catch (error) {
                console.error("error sub", error);
              }
            });
          else array.push(arr);
          arr = [];
        });

        //end shifting

        array[0][2] = {
          content: "",
          rowSpan: array.length,
          styles: {
            lineColor: [...this.bulletinColors.color5],
          },
        };
        //appreciation

        if (
          !m.submodules ||
          m.submodules[0].NbSubjects == 0 ||
          m.submodules[1].NbSubjects == 0
        ) {
          array[0][3] = {
            content: m.appreciation.trim(),
            rowSpan: array.length,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: m.appreciation.length > 15 ? 10 : 12,
              lineColor: [...this.bulletinColors.color5],
            },
          };
        } else {
          array[0][3] = {
            content: m.submodules[0].appreciation.trim(),
            rowSpan: m.submodules[0].NbSubjects,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
          array[m.submodules[0].NbSubjects][3] = {
            content: m.submodules[1].appreciation.trim(),
            rowSpan: m.submodules[1].NbSubjects,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
        }
        //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
        array[0][4] = {
          content: m.moyenne,
          rowSpan: array.length,
          styles: {
            halign: "center",
            fontSize: 12,
            valign: "center",
            lineColor: [...this.bulletinColors.color5],
          },
        };

        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }
        doc.autoTable({
          startY: 64 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          body: array,
          margin: { left: 65, bottom: 0 },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },
          columnStyles: {
            0: { cellWidth: 10 },
            1: { cellWidth: 10 },
            2: { cellWidth: 1 },
            3: { cellWidth: 38 },
            4: { cellWidth: 15 },
            5: { cellWidth: 16 },
            6: { cellWidth: 8 },
            7: { cellWidth: 18 },
            8: { cellWidth: 23 },
          },
        });

        topMargin = doc.lastAutoTable.finalY - 61.4 + 2;
        /*} catch (error) {
          console.error("error.stack");

          console.trace(error);
        }*/
      });

      this.drawTrimesterMoyenneAndCards(
        doc,
        ctx,
        -27,
        student.moyenne,
        bulletinData.maxMoyenne,
        bulletinData.minMoyenne,
        student.pedaMoyenne,
        affichageBulletin,
        stampImage
      );
    },
    async drawBulletinMondial(
      doc,
      ctx,
      student,
      bulletinData,
      affichageBulletin,
      stampImage
    ) {
      this.bulletinColors = {
        color1: [70, 187, 239],
        color2: [64, 187, 239],
        color3: [16, 169, 229],
        color4: [67, 62, 63],
        color5: [52, 172, 215],
        color6: [224, 237, 243],
        color7: [64, 187, 239],
        color8: [225, 225, 226],
        color9: [224, 237, 243],
      };
      const record = student;

      const pdf_width = doc.internal.pageSize.width;
      let width = 0;
      let width2 = 0;

      // ending header line
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.8);
      doc.line(0, 40 - 18, pdf_width, 40 - 18);

      // trimester rounded rect title
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(pdf_width / 2 - 25, 34 - 18, 48, 12, 4, 4, "FD");

      // trimester text
      doc.setFontSize(16);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(...this.bulletinColors.color5);
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "الثلاثي الأول";
          break;
        case "2":
          title = "الثلاثي الثاني";
          break;
        case "3":
          title = "الثلاثي الثالث";
          break;
      }
      doc.setFont("Noto Sans Arabic", "Bold");
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 41.2 - 18);

      const className = this.selectedClassName;

      let studentLabel =
        record.gender === "male" ? " : التلميذ" : " : التلميذة";

      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);

      doc.setTextColor(0, 0, 0);

      let schoolTypeText =
        this.selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
      text = this.schoolDetails.ArabicName
        ? schoolTypeText + this.schoolDetails.ArabicName
        : "................................................... : المدرسة الابتدائيّة";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 8);
      text = ` ${this.schoolarYearName}  : السنة الدراسيّة`;
      width = doc.getTextWidth(text);
      doc.text(text, 10, 8);

      let text = studentLabel;
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 14.5);

      text = student.fullName;
      doc.setFont("Amiri", "normal");
      doc.setFontSize(13);
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - width2 - 12, 14.5);
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      // student classroom
      text = " : القسم";
      doc.setTextColor(0, 0, 0);
      width = doc.getTextWidth(text);
      width2 = doc.getTextWidth(className);
      doc.text(text, 13 + width2, 14.5);

      doc.text(className, 12, 14.5);
      // number of students in classroom
      if (affichageBulletin.numberStudentInsc) {
        text = String(
          Array.isArray(bulletinData.students)
            ? bulletinData.students.length
            : bulletinData.students
        );
        width2 = doc.getTextWidth(text);
        doc.text(text, 12, 20);
        text = " : عدد التلاميذ المرسّمين";
        width = doc.getTextWidth(text);
        doc.text(text, 12 + width2, 20);
      }

      // identUnique
      if (affichageBulletin.identUnique && student.uniqueId) {
        text = " : المعرف الوحيد";
        width = doc.getTextWidth(text);
        doc.text(text, pdf_width - width - 12, 20);
        text = String(student.uniqueId);
        width2 = doc.getTextWidth(text);
        doc.text(text, pdf_width - width2 - width - 12, 20);
      }
      const cellToDelete = {};
      let topMargin = -41;
      let tableSize = 9;
      switch (this.selectedLevel) {
        case 1:
          tableSize = 11.5;
          break;
        case 2:
          tableSize = 11.5;
          break;
        case 3:
          tableSize = 10.5;
          break;
        case 4:
          tableSize = 9.5;
          break;
        default:
          break;
      }

      student.modules.forEach((m) => {
        //try {
        let arr = [];
        let array = [];

        this.drawModuleHeaderMondial(doc, ctx, topMargin, m.name);
        topMargin += 17.2;

        m.subjects.forEach((c) => {
          let subFormattedName = c.name;
          if (c.name.length > 50 && c.name.indexOf(" و ") != -1) {
            let indexOfFirst = c.name.indexOf(" و ");
            subFormattedName =
              c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
          }
          let formattedModuName = m.name;
          if (m.name.length > 23 && m.name.indexOf(" و ") != -1) {
            let indexOfFirst = m.name.indexOf(" و ");
            formattedModuName =
              m.name.slice(0, indexOfFirst) + "\n" + m.name.slice(indexOfFirst);
          }
          const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
          arr[9] = {
            content: formattedModuName,
            rowSpan: m.subjects.length + spanNumber - 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "middle",
              cellPadding: {
                right: 1,
                left: 1,
              },
              fillColor: this.rgbToHex(...this.bulletinColors.color7),
              fontSize: 10,
              textColor: "#ffffff",
              lineColor: [229, 229, 229],
            },
          };
          //ﺍﻟﻤﺎﺩﺓ

          arr[8] = {
            content: subFormattedName,
            rowSpan: spanNumber,
            colSpan: spanNumber == 1 ? 3 : 1,
            styles: {
              halign: "right",
              valign: "middle",

              fillColor: this.rgbToHex(...this.bulletinColors.color7),
              fontSize: tableSize,
              textColor: "#ffffff",
              lineColor: [229, 229, 229],
            },
          };
          // ﺍﻟﻌﺪﺩ / 20
          arr[5] = {
            content: c.mark,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          arr[4] = cellToDelete;
          arr[3] = cellToDelete;
          arr[2] = cellToDelete;

          //best mark
          arr[1] = {
            content: c.max,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 9,
              cellPadding: {
                right: 0,
                left: 0,
                top: 0,
                bottom: 1.25,
              },
              lineColor: [...this.bulletinColors.color5],
            },
          };

          //lowest mark
          arr[0] = {
            content: c.min,
            rowSpan: spanNumber,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 9,
              cellPadding: {
                right: 0,
                left: 0,
                top: 0,
                bottom: 1.25,
              },
              lineColor: [...this.bulletinColors.color5],
            },
          };
          //sub subjects
          if (c.subSubjectMarks)
            c.subSubjectMarks.forEach((subs) => {
              try {
                arr[6] = {
                  content: subs.mark,
                  styles: {
                    halign: "center",
                    valign: "middle",
                    cellPadding: 0,
                    fontSize: 8,
                    fillColor: this.rgbToHex(...this.bulletinColors.color9),
                    textColor: this.rgbToHex(...this.bulletinColors.color2),
                    lineColor: this.bulletinColors.color2,
                  },
                };

                arr[7] = {
                  content: `${subs.name} (/${subs.maxMark})`,
                  styles: {
                    halign: "right",
                    valign: "middle",
                    fontSize: 7,
                    fillColor: this.rgbToHex(...this.bulletinColors.color9),
                    textColor: this.rgbToHex(...this.bulletinColors.color2),
                    lineColor: this.bulletinColors.color2,
                  },
                };
                array.push(arr);
                arr = [];
              } catch (error) {
                console.error("error sub", error);
              }
            });
          else array.push(arr);
          arr = [];
        });

        //end shifting

        array[0][2] = {
          content: "",
          rowSpan: array.length,
          styles: {
            lineColor: [...this.bulletinColors.color5],
          },
        };
        //appreciation

        if (
          !m.submodules ||
          m.submodules[0].NbSubjects == 0 ||
          m.submodules[1].NbSubjects == 0
        ) {
          array[0][3] = {
            content: m.appreciation.trim(),
            rowSpan: array.length,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: m.appreciation.length > 15 ? 10 : 12,
              lineColor: [...this.bulletinColors.color5],
            },
          };
        } else {
          array[0][3] = {
            content: m.submodules[0].appreciation.trim(),
            rowSpan: m.submodules[0].NbSubjects,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
          array[m.submodules[0].NbSubjects][3] = {
            content: m.submodules[1].appreciation.trim(),
            rowSpan: m.submodules[1].NbSubjects,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
        }
        //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
        array[0][4] = {
          content: m.moyenne,
          rowSpan: array.length,
          styles: {
            halign: "center",
            fontSize: 12,
            valign: "center",
            lineColor: [...this.bulletinColors.color5],
          },
        };

        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }
        doc.autoTable({
          startY: 64 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          body: array,
          margin: { left: 5, right: 5, bottom: 0 },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },
          columnStyles: {
            0: { cellWidth: 10 },
            1: { cellWidth: 10 },
            2: { cellWidth: 1 },
            3: { cellWidth: 38 },
            4: { cellWidth: 15 },
            5: { cellWidth: 16 },
            6: { cellWidth: 8 },
            7: { cellWidth: 40 },
            8: { cellWidth: 23 },
            9: { cellWidth: 40 },
          },
        });

        topMargin = doc.lastAutoTable.finalY - 73 + 2;
      });

      // Generate the table
      let header = [
        "إمضاء الولي",
        "الشهادة",
        "أدنى معدل",
        "أعلى معدل",
        "معدل الثلاثي",
      ];
      let formatedHeader = header.map((name, index) => {
        let fillColor = this.rgbToHex(...this.bulletinColors.color9);
        let textColor = this.rgbToHex(...this.bulletinColors.color2);
        let lineColor = this.bulletinColors.color2;
        if (index == 0 || index == 4) {
          fillColor = this.rgbToHex(...this.bulletinColors.color7);
          textColor = "#ffffff";
          lineColor = [229, 229, 229];
        }

        return {
          content: name,
          styles: {
            halign: "center",
            valign: "middle",
            fillColor: fillColor,
            textColor: textColor,
            lineColor: lineColor,
          },
        };
      });
      let arr = [];

      arr[0] = "";
      arr[1] = affichageBulletin.certif
        ? this.getCertificationNameByMoyenne(student.moyenne)
        : "";
      arr[2] = bulletinData.minMoyenne;
      arr[3] = bulletinData.maxMoyenne;
      arr[4] = student.moyenne;
      let array = [];
      array.push(formatedHeader);
      array.push(arr);
      let startYFinalTab = doc.previousAutoTable.finalY + 5;
      doc.autoTable({
        startY: startYFinalTab, // Add margin
        body: array,
        theme: "grid",
        styles: {
          font: "Amiri",
          halign: "center",
          fontStyle: "Bold",
        },
        cellPadding: 0,
        margin: { left: 40, right: 5, bottom: 0, top: 5 },
        columnStyles: {
          0: { cellWidth: 47 },
          1: { cellWidth: 33 },
          2: { cellWidth: 28 },
          3: { cellWidth: 28 },
          4: { cellWidth: 28 },
        },
      });
      //stamp
      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0);
      text = "امضاء مدير)ة( المدرسة";

      doc.text(text, 5, startYFinalTab + 5);

      if (stampImage && stampImage.src) {
        try {
          doc.addImage(stampImage, "PNG", 5, startYFinalTab - 10, 35, 35);
        } catch (e) {}
      }
      //end stamp
    },
    drawModuleHeader(doc, ctx, startY, title) {
      // module title

      let x = 65;
      let y = 63 + startY;
      let width = 125;
      doc.setFillColor(...this.bulletinColors.color2);
      this.roundRect(ctx, x, y, width, 8, {}, true, true);
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setDrawColor(0xff, 0xff, 0xff);
      doc.rect(x, y + 20, width + 10, 10, "FD");

      // module header shapes
      x = 155;
      y = 72.6 + startY;
      width = 47;
      doc.setFillColor(...this.bulletinColors.color2);
      this.roundRect(ctx, x, y, width, 7.5, {}, true, true);
      // doc.setFillColor(0xff, 0xff, 0xff);
      // doc.setDrawColor(0xff, 0xff, 0xff);
      // doc.rect(x, y + 9.9, width + 10, 10, "FD");

      x = 139;
      y = 72.6 + startY;
      width = 16;
      doc.setFillColor(...this.bulletinColors.color6);
      //العدد / 20
      this.roundRect(ctx, x, y, width, 7.4, {}, true, true);
      // doc.setFillColor(0xff, 0xff, 0xff);
      // doc.setDrawColor(0xff, 0xff, 0xff);
      // doc.rect(x, y + 9.9, width + 10, 10, "FD");

      doc.setLineWidth(0.4);
      doc.setFillColor(...this.bulletinColors.color6);
      doc.setDrawColor(...this.bulletinColors.color2);
      //معدل المجال;
      doc.rect(124, 72.3 + startY, 15, 8, "FD");
      //توصيّات المدّرس
      doc.rect(86, 72.3 + startY, 38, 8, "FD");
      //عدد بالقسم

      doc.rect(65, 72.3 + startY, 10, 8, "FD");
      doc.rect(75, 72.3 + startY, 10, 8, "FD");

      doc.line(155, 80.3 + startY, 202.5, 80.3 + startY);

      // header module texts
      if (/[a-zA-Z]/.test(title)) doc.setFont("Amiri", "Bold");
      else doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(13);
      let text = title;
      doc.setTextColor(0xff, 0xff, 0xff);
      width = doc.getTextWidth(text);
      doc.text(text, 138 - width / 2 - 15, 69 + startY);

      doc.setFont("Amiri", "Bold");
      text = "المادة";
      width = doc.getTextWidth(text);
      doc.text(text, 179, 78 + startY);

      text = "العدد / 20";
      doc.setFontSize(9);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 141, 78 + startY);

      text = "معدل\nالمجال";
      doc.setFontSize(9);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 132, 75.2 + startY, "center");

      text = "توصيّات المدّرس";
      doc.setFontSize(11);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 94, 77.5 + startY);

      text = "أعلى\nعدد بالقسم";
      doc.setFontSize(6);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      /**
       * style == 0 ? 80 : 65, 72.3 + startY, 10, 8, "FD");
      doc.rect(style == 0 ? 90 : 75,
       */
      doc.text(text, 80, 75 + startY, "center");

      text = "أدنى\nعدد بالقسم";
      doc.setFontSize(6);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 70, 75 + startY, "center");
    },
    drawModuleHeaderMondial(doc, ctx, startY, title) {
      // module title
      let x = 65;
      let y = 63 + startY;
      let width = 125;
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setDrawColor(0xff, 0xff, 0xff);
      //doc.rect(x, y + 20, width + 10, 10, "FD");

      // module header shapes
      x = 95;
      y = 72.6 + startY;
      width = 109;
      doc.setFillColor(...this.bulletinColors.color2);
      // المادة rect
      this.roundRect(ctx, x, y, width, 7.5, {}, true, true);

      x = 139 - 60;
      y = 72.6 + startY;
      width = 16;
      doc.setFillColor(...this.bulletinColors.color6);
      this.roundRect(ctx, x, y, width, 7.4, {}, true, true);

      doc.setLineWidth(0.4);
      doc.setFillColor(...this.bulletinColors.color6);
      doc.setDrawColor(...this.bulletinColors.color2);
      doc.rect(124 - 60, 72.3 + startY, 15, 8, "FD");
      doc.rect(86 - 60, 72.3 + startY, 38, 8, "FD");
      doc.rect(65 - 60, 72.3 + startY, 10, 8, "FD");
      doc.rect(75 - 60, 72.3 + startY, 10, 8, "FD");

      // doc.line(155 - 60, 80.3 + startY, 202.5, 80.3 + startY);

      // Add new rectangle for "Module"
      x = 202.5 - 60;
      y = 72.3 + startY;
      width = 25;
      let height = 8;
      doc.setFillColor(...this.bulletinColors.color2);
      doc.setDrawColor(...this.bulletinColors.color2);
      // doc.rect(x, y, width, height, "FD");

      // Add text "Module" inside the new rectangle
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(13);
      doc.setTextColor(0xff, 0xff, 0xff);
      let text = "المجال";
      let textWidth = doc.getTextWidth(text);
      doc.text(text, 171, y + height - 2);

      doc.setFont("Amiri", "Bold");
      text = "المادة";
      width = doc.getTextWidth(text);
      doc.text(text, 179 - 60, 78 + startY);

      text = "العدد / 20";
      doc.setFontSize(9);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 141 - 60, 78 + startY);

      text = "معدل\nالمجال";
      doc.setFontSize(9);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 132 - 60, 75.2 + startY, "center");

      text = "توصيّات المدّرس";
      doc.setFontSize(11);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 94 - 60, 77.5 + startY);

      text = "أعلى\nعدد بالقسم";
      doc.setFontSize(6);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 80 - 60, 75 + startY, "center");

      text = "أدنى\nعدد بالقسم";
      doc.setFontSize(6);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, 70 - 60, 75 + startY, "center");
    },

    roundRect(ctx, x, y, width, height, radius, fill, stroke) {
      const cornerRadius = {
        upperLeft: 0,
        upperRight: 0,
        lowerLeft: 0,
        lowerRight: 0,
      };
      if (typeof stroke == "undefined") {
        stroke = true;
      }
      if (typeof radius === "object") {
        for (const side in radius) {
          cornerRadius[side] = radius[side];
        }
      }

      ctx.lineWidth = 1;
      ctx.strokeStyle = `rgb(${this.bulletinColors.color1[0]}, ${this.bulletinColors.color1[1]}, ${this.bulletinColors.color1[2]})`;

      ctx.beginPath();
      ctx.moveTo(x + cornerRadius.upperLeft, y);
      ctx.lineTo(x + width - cornerRadius.upperRight, y);
      ctx.quadraticCurveTo(
        x + width,
        y,
        x + width,
        y + cornerRadius.upperRight
      );
      ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - cornerRadius.lowerRight,
        y + height
      );
      ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
      ctx.quadraticCurveTo(
        x,
        y + height,
        x,
        y + height - cornerRadius.lowerLeft
      );
      ctx.lineTo(x, y + cornerRadius.upperLeft);
      ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
      ctx.closePath();
      if (stroke) {
        ctx.stroke();
      }
      if (fill) {
        ctx.fill();
      }
    },
    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },

    rgbToHex(r, g, b) {
      return (
        "#" +
        this.componentToHex(r) +
        this.componentToHex(g) +
        this.componentToHex(b)
      );
    },
    drawTrimesterMoyenneAndCards(
      doc,
      ctx,
      startY,
      moy,
      max,
      min,
      pedaMoyenne,
      affichageBulletin,
      stampImage
    ) {
      const value = 5;
      let yPosition = startY + 68.2;

      let text;
      let width;

      if (affichageBulletin.verySpecific) {
        doc.setFillColor(...this.bulletinColors.color5);
        doc.setDrawColor(...this.bulletinColors.color5);
        this.roundRect(
          ctx,
          45 - value * 2,
          yPosition,
          33.5 - value,
          8.2,
          {},
          true,
          false
        );

        doc.setFont("Amiri", "Bold");
        doc.setFontSize(11);
        text = affichageBulletin.verySpecificName;
        doc.setTextColor(0xff, 0xff, 0xff);
        width = doc.getTextWidth(text);
        doc.text(text, 65.5 - value * 3 - width / 2, yPosition + 5.3);

        doc.setLineWidth(0.2);
        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);

        this.roundRect(ctx, 6, yPosition, 38 - value * 2, 7.8, {}, true, true);
        this.roundRect(ctx, 6, yPosition, 19 - value, 7.8, {}, true, true);

        doc.setFont("Amiri", "Bold");
        doc.setFontSize(7);
        doc.setTextColor(...this.bulletinColors.color2);
        doc.text(
          "أعلى\nمعدل بالقسم",
          39 - (value + value / 2),
          yPosition + 3.3,
          "right"
        );
        doc.text(
          "أدنى\nمعدل بالقسم",
          20.2 - value / 2,
          yPosition + 3.3,
          "right"
        );

        // moyenne rect
        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);
        doc.setLineWidth(0.15);
        doc.rect(45 - value * 2, yPosition + 9.3, 33.5 - value, 16.4, "FD");

        doc.setFillColor(...this.bulletinColors.color8);
        doc.rect(6, yPosition + 9.3, 19 - value, 16.4, "FD");
        doc.rect(25 - value, yPosition + 9.3, 19 - value, 16.4, "FD");

        // moyenne text
        doc.setTextColor(...this.bulletinColors.color4);
        doc.setFontSize(16);
        text = String(moy);
        width = doc.getTextWidth(text);
        doc.text(
          text,
          63 - width / 2 - (value * 2 + value / 2),
          yPosition + 18.3
        );

        doc.setFontSize(13);
        text = String(max);
        width = doc.getTextWidth(text);
        doc.text(
          text,
          34.75 - width / 2 - (value + value / 2),
          yPosition + 18.3
        );

        text = String(min);
        width = doc.getTextWidth(text);
        doc.text(text, 15.25 - width / 2 - value / 2, yPosition + 18.3);

        yPosition += 27; //99.2
      }

      if (affichageBulletin.pedagogic) {
        doc.setFillColor(...this.bulletinColors.color5);
        doc.setDrawColor(...this.bulletinColors.color2);
        this.roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);
        doc.setLineWidth(0.05);

        doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

        doc.setFontSize(10);
        text = affichageBulletin.pedagogicName;
        width = doc.getTextWidth(text);
        doc.setTextColor(0xff, 0xff, 0xff);
        doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

        doc.setFontSize(11);
        text = pedaMoyenne ? String(pedaMoyenne) : "--";
        width = doc.getTextWidth(text);
        doc.setTextColor(...this.bulletinColors.color4);
        doc.text(text, 45 - width / 2 - value * 2, yPosition + 14.3);

        yPosition += 20; //110
      }

      if (affichageBulletin.personalized) {
        doc.setFillColor(...this.bulletinColors.color5);
        doc.setDrawColor(...this.bulletinColors.color2);
        this.roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);
        doc.setLineWidth(0.05);
        doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

        doc.setFontSize(10);
        text = affichageBulletin.personalizedName;
        width = doc.getTextWidth(text);
        doc.setTextColor(0xff, 0xff, 0xff);
        doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

        let perMoyenne = "--";
        if (pedaMoyenne && moy && pedaMoyenne != "--" && moy != "--") {
          const totalCoef =
            affichageBulletin.pedagogicCoef +
            affichageBulletin.verySpecificCoef;
          perMoyenne = String(
            (
              (Number(moy) * affichageBulletin.verySpecificCoef +
                Number(pedaMoyenne) * affichageBulletin.pedagogicCoef) /
              totalCoef
            ).toFixed(2)
          );
        }
        doc.setFontSize(11);
        width = doc.getTextWidth(perMoyenne);
        doc.setTextColor(...this.bulletinColors.color4);
        doc.text(perMoyenne, 45 - width / 2 - value * 2, yPosition + 14.5);

        yPosition += 20; // 121
      }
      //الشّهادة
      if (affichageBulletin.certif) {
        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);

        yPosition -= 36;

        doc.rect(6, yPosition + 42, 72.5 - value * 3, 20);
        doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

        doc.setFontSize(13);

        doc.setTextColor(...this.bulletinColors.color2);

        text = "الشّهادة";
        doc.text(text, 55 - value * 3, yPosition + 43);

        doc.setTextColor(67, 62, 63);

        doc.text(
          this.getCertificationNameByMoyenne(moy),

          40 - value * 3,
          yPosition + 55
        );
        yPosition += 65;
      }
      //مدير)ة( المدرسة"
      doc.setLineWidth(0.15);

      doc.setFillColor(...this.bulletinColors.color6);
      doc.setDrawColor(...this.bulletinColors.color2);

      yPosition -= 36;

      doc.rect(6, yPosition + 42, 72.5 - value * 3, 55);
      doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

      doc.rect(6, yPosition + 106, 72.5 - value * 3, 30);
      doc.rect(40.75 - value * 3, yPosition + 102, 36.75, 8, "FD");

      doc.setFontSize(13);

      doc.setTextColor(...this.bulletinColors.color2);

      text = this.selectedLevel > 9 ? "مدير)ة( المعهد" : "مدير)ة( المدرسة";
      doc.text(text, 45 - value * 3, yPosition + 43);

      doc.setTextColor(67, 62, 63);
      doc.text(
        this.schoolDetails.headMaster +
          "\n" +
          moment(this.bulletinDate).format("YYYY/MM/DD"),
        30 - value * 3,
        yPosition + 51
      );

      doc.setTextColor(...this.bulletinColors.color2);
      doc.setFontSize(16);

      text = "إمضاء الولي";
      doc.text(text, 48 - value * 3, yPosition + 107);

      //stamp
      if (stampImage && stampImage.src) {
        try {
          doc.addImage(stampImage, "PNG", 10, yPosition + 61, 30, 30);
        } catch (e) {}
      }
      //end stamp
    },
    drawModuleFooter(doc, ctx, startY, avg) {
      let x = 158;
      let y = startY;
      let width = 35.5;
      doc.setFillColor(...this.bulletinColors.color3);
      doc.setDrawColor(...this.bulletinColors.color3);
      doc.rect(x, y, width + 10, 8.5, "FD");

      x = 111;
      y = startY + 0.4;
      width = 45.5;
      doc.setFillColor(255, 255, 255);

      this.roundRect(ctx, x, y, width, 7.8, {}, true, true);

      // footer text (moyenne)
      doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(13);
      let text = "معدل المجال";
      doc.setTextColor(0xff, 0xff, 0xff);
      doc.text(text, 169, startY + 5.5);

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(14);
      text = String(avg);
      doc.setTextColor(...this.bulletinColors.color4);
      width = doc.getTextWidth(text);
      doc.text(text, 134.75 - width / 2, startY + 5.5);
    },
    async getModules(level) {
      await apiClient
        .post("/moduleVerySpecific/filter", {
          query: {
            status: "active",
            level: level,
            trimester: this.selectedTrimester,
          },
        })
        .then((res) => {
          this.subjectsLoading = true;

          if (res.data.length == 0)
            return this.$message.warning(this.$t("error.aucModule"));

          this.subjectList = [];

          res.data.forEach((module) => {
            module.subjects.forEach((subject) => {
              const subsubjects = module.subjectsInfo[subject._id];
              subject.divided = false;
              if (subsubjects && subsubjects.subsubjectsMark) {
                subject.divided = true;
                subject.subsubjectData = [];
                for (const key in subsubjects.subsubjectsMark) {
                  const s = module.subsubjects.find(
                    (s) => String(s._id) == String(key)
                  );

                  const subsubject = subsubjects.subsubjectsMark[key];
                  subject.subsubjectData.push({
                    name: s.name,
                    _id: s._id,
                    divided: true,
                    maxMark: subsubject,
                  });
                }
              }

              this.subjectList.push(subject);
            });
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
          this.moduleLoading = false;
        });
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async saveAll() {
      this.saveLoading = true;

      for (const subject of this.subjectList) {
        // check if subsubject marks are completed
        if (subject.divided) {
          const subsubjects = subject.subsubjectData.map((s) => String(s._id));
          const marks = this.markData.filter((m) => {
            return (
              m.student == this.selectedStudent &&
              m.divided &&
              subsubjects.includes(m.subsubject)
            );
          });

          if (marks.length != 0 && marks.length != subsubjects.length) {
            this.saveLoading = false;
            return this.$message.warning(
              this.$t("warning.missedSubsubjects", {
                name: subject.name,
              })
            );
          }
        }
      }

      // check if marks are correct
      let thereError = false;
      for (const mark of this.markData) {
        if (!mark.divided) {
          if (isNaN(Number(mark.mark)) || mark.mark > 20 || mark.mark < 0) {
            thereError = true;
            break;
          }
        } else if (mark.divided) {
          let subsubject;
          for (const subject of this.subjectList) {
            if (subject.divided) {
              subsubject = subject.subsubjectData.find(
                (s) => s._id == mark.subsubject
              );
              if (subsubject) break;
            }
          }

          if (subsubject)
            if (
              isNaN(Number(mark.mark)) ||
              mark.mark > subsubject.maxMark ||
              mark.mark < 0
            ) {
              thereError = true;
              break;
            }
        }
      }

      if (thereError) {
        this.$message.warning(this.$t("warning.notCorrectMarks"));
        this.saveLoading = false;
        return;
      }

      // saving
      const toAddData = [];

      for (const mark of this.markData) {
        const originalMark = this.originalMarkData.find(
          (m) =>
            m.student == mark.student &&
            ((!m.divided && m.subject == mark.subject) ||
              (m.divided && m.subsubject == mark.subsubject))
        );
        if (originalMark) {
          if (Number(mark.mark) != Number(originalMark.mark))
            await apiClient
              .patch("/markvspecific/" + originalMark._id, {
                data: {
                  mark: mark.mark,
                },
              })
              .then()
              .catch((err) => console.log(err));
        } else {
          const obj = {
            divided: mark.divided,
            student: mark.student,
            mark: mark.mark,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
            subject: mark.subject,
            type: "one",
          };
          if (mark.divided) obj.subsubject = mark.subsubject;
          toAddData.push(obj);
        }
      }

      await apiClient
        .put("/markvspecific/many", {
          data: toAddData,
        })
        .then()
        .catch((err) => console.log(err));

      for (const originalMark of this.originalMarkData) {
        const mark = this.markData.find(
          (m) =>
            m.student == originalMark.student &&
            ((!m.divided && m.subject == originalMark.subject) ||
              (m.divided && m.subsubject == originalMark.subsubject))
        );
        if (!mark)
          await apiClient
            .delete("/markvspecific/" + originalMark._id)
            .then()
            .catch((err) => console.log(err));
      }

      await this.updateMarks();

      this.saveLoading = false;
      this.updatedChanges = true;
      this.$message.success(this.$t("success.noteToutesEnregistre"));
    },

    deleteMark(id, studentId, divided) {
      this.markData = this.markData.filter(
        (m) =>
          !(
            m.student == studentId &&
            m.divided == divided &&
            (m.subsubject == id || m.subject == id)
          )
      );
      this.updatedChanges = false;
    },

    handleMarkChange(newMark, subjectId, subSubjectId, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          ((divided && m.subsubject == subSubjectId) ||
            (!divided && m.subject == subjectId))
      );
      if (mark) mark.mark = Number(newMark);
      else {
        const obj = {
          student: studentId,
          mark: Number(newMark),
          divided: divided,
          subject: subjectId,
        };
        if (divided) obj.subsubject = subSubjectId;

        this.markData.push(obj);
      }

      this.updatedChanges = false;
    },

    getMarkValue(id, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          m.divided == divided &&
          (m.subsubject == id || m.subject == id)
      );
      if (mark) return mark.mark;
      return null;
    },

    getTotalSubsubjects(record) {
      try {
        const marks = this.markData.filter((m) => {
          return m.divided && m.subject == record._id;
        });

        let total = 0;
        marks.forEach((mark) => {
          total += mark.mark;
        });

        let sumMax = this.getSumMaxMArks(record.subsubjectData);
        return total ? ((total * 20) / sumMax).toFixed(2) : "";
      } catch (error) {
        console.log(error);
      }
    },

    async handleStudentChange(val) {
      this.columns = [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("bulletin.note"),
          dataIndex: "note",
          scopedSlots: { customRender: "note" },
        },
      ];

      this.updateMarks();
    },

    async updateMarks() {
      this.tableLoading = true;
      await apiClient
        .post("/markvspecific/filter", {
          query: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            student: this.selectedStudent,
            type: {
              $in: ["one"],
            },
          },
        })
        .then(({ data }) => {
          this.markData = [];
          this.originalMarkData = [];
          this.originalMarkData = data;
          data.map((mark) => {
            this.markData.push({
              student: mark.student,
              subject: mark.subject,
              subsubject: mark.subsubject,
              mark: mark.mark,
              divided: mark.divided,
            });
          });
        })
        .finally(() => (this.tableLoading = false));
    },

    async handleClassChange(on, value) {
      this.studentLoading = true;
      this.selectedStudent = "";
      this.markRefs = [];
      this.moduleLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                firstNameLower: { $toLower: "$firstName" },
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
          this.studentLoading = false;
        });
      //get modules
      this.getModules(this.selectedLevel);
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.danger .ant-input-number {
  border-color: #f5222e !important;
}

.danger .ant-input-number-focused {
  border-color: #f5222e !important;
}

.ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group-addon {
  display: inline-block !important;
  height: 35px !important;
  width: 40px !important;
  padding-top: 5px !important;
  /* color: #a3ba99d2;*/
}
</style>
